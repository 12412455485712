
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'150px'"
      >
        <el-form-item label="设备编号" prop="indoorDeviceInfo.deviceNo">
          <el-input
            v-model="formModel.indoorDeviceInfo.deviceNo"
            placeholder="请输入设备编号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备安装地址" prop="indoorDeviceInfo.address">
          <el-input
            v-model="formModel.indoorDeviceInfo.address"
            placeholder="请输入设备安装地址"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="房号" prop="indoorDeviceInfo.roomName">
          <el-input
            v-model="formModel.indoorDeviceInfo.roomName"
            placeholder="请输入房号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="关联门禁设备" prop="relatedDeviceList">
          <el-select
            v-model="formModel.relatedDeviceList"
            multiple
            filterable
            clearable
            remote
            :remote-method="queryDevice"
            placeholder="请选择门禁设备"
            style="width: 300px"
          >
            <el-option
              v-for="item in deviceList"
              :label="item.aliasName"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import indoorDeviceInfoApi from "@/api/base/indoorDeviceInfo";
import deviceInfoApi from "@/api/base/deviceInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        "indoorDeviceInfo.deviceNo": [
          { required: true, message: "设备编号不能为空", trigger: "blur" },
        ],
        "indoorDeviceInfo.address": [
          { required: true, message: "设备安装地址不能为空", trigger: "blur" },
        ],
        "indoorDeviceInfo.roomName": [
          {
            required: true,
            message: "房号不能为空",
            trigger: "blur",
          },
        ],
        relatedDeviceList: [
          { required: true, message: "门禁设备不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      deviceList: [],
      formModel: {
        indoorDeviceInfo: {},
        relatedDeviceList: [],
      },
    };
  },
  methods: {
    queryDevice(aliasName) {
      var self = this;
      var formData = new FormData();
      formData.append("aliasName", aliasName);
      formData.append("limit", 20);

      deviceInfoApi.query(formData).then(function (response) {
        var jsonData = response.data;

        if (jsonData.result) {
          if (jsonData.data != null && jsonData.data != "") {
            self.deviceList = jsonData.data;
          }
        }
      });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.indoorDeviceInfo.id;
            self.loading = true;

            if (id == null || id.length == 0) {
              return indoorDeviceInfoApi.add(self.formModel);
            } else {
              return indoorDeviceInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            self.loading = false;
            
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    initDeviceList() {
      var self = this;
      var formData = new FormData();
      formData.append("aliasName", "");
      formData.append("limit", 20);

      deviceInfoApi.query(formData).then(function (response) {
        var jsonData = response.data;

        if (jsonData.result) {
          if (jsonData.data != null) {
            jsonData.data.forEach(item=>{
              var index = 0;
              
              if(self.formModel.relatedDeviceList!=null){
                index = self.formModel.relatedDeviceList.indexOf(item.id);
              }
              
              if(index==-1){
                self.deviceList.push(item);
              }
            });
          }
        }
      });
    }
  },
  mounted: function () {
    var self = this;
    self.loading = true;

    (function () {
      if (self.businessKey.length == 0) {
        return indoorDeviceInfoApi.create();
      } else {
        return indoorDeviceInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel.indoorDeviceInfo = jsonData.data.indoorDeviceInfo;

          if (jsonData.data.relatedDeviceList != null) {
            self.formModel.relatedDeviceList = jsonData.data.relatedDeviceList.map((item) => item.id);
            self.deviceList = jsonData.data.relatedDeviceList;
          }

          self.initDeviceList();
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>