<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">基础信息</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/indoorDeviceInfo">室内机管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="设备编号" prop="deviceNo">
          <el-input type="text" size="mini" v-model="queryModel.deviceNo"></el-input>
      </el-form-item>
      <el-form-item label="安装地址" prop="address">
          <el-input type="text" size="mini" v-model="queryModel.address"></el-input>
      </el-form-item>
      <el-form-item label="房号" prop="roomName">
          <el-input type="text" size="mini" v-model="queryModel.roomName"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
		<el-table-column prop="deviceNo" sort-by="device_no" label="设备编号" sortable="custom" width="180"></el-table-column>
		<el-table-column prop="address" sort-by="address_" label="设备安装地址" sortable="custom" width="180"></el-table-column>
		<el-table-column prop="roomName" sort-by="room_name" label="房号" sortable="custom" width="180"></el-table-column>
		<el-table-column prop="online" sort-by="online_" label="是否在线" sortable="custom" width="180">
      <template slot-scope="{row}">
          <div
            v-if="row.online"
            style="border-radius: 30px;background-color:#67C23A;width:20px;height:20px;"></div>
          <div
            v-if="!row.online"
            style="border-radius: 30px;background-color:#F56C6C;width:20px;height:20px;"></div>
      </template>      
    </el-table-column>
		<el-table-column prop="lastOnlineTime" sort-by="last_online_time" label="最后上线时间" sortable="custom" width="180"></el-table-column>
		<el-table-column prop="createTime" sort-by="create_time" label="创建时间" sortable="custom" width="180"></el-table-column>
		<el-table-column prop="updateTime" sort-by="update_time" label="更新时间" sortable="custom" width="180"></el-table-column>
		<el-table-column label="操作" fixed="right" width="200">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
	<indoorDeviceInfo-detail
	v-if="showModal"
	:businessKey="businessKey"
	:title="modalTitle"
	@close="onDetailModalClose"
	></indoorDeviceInfo-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import IndoorDeviceInfoDetail from "./indoorDeviceInfo-detail";
import indoorDeviceInfoApi from "@/api/base/indoorDeviceInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BaseIndoorDeviceInfoList",
  data() {
    var self = this;

    return {
      queryModel: {
        "deviceNo":"", 
        "address":"",
        "roomName":""
			},
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],      
      showModal: false,
      modalTitle: "",
      businessKey: ""
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;
      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("deviceNo",self.queryModel.deviceNo);
      formData.append("address",self.queryModel.address);
      formData.append("roomName",self.queryModel.roomName);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      indoorDeviceInfoApi.pageList(formData).then(function(response) {
        self.loading = false;

        var jsonData = response.data.data;

        self.tableData = jsonData.data;
        self.totalPages = jsonData.totalPages;
        self.totalElements = jsonData.recordsTotal;
      }).catch((error)=>{
        self.loading = false;
      });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      
      this.$nextTick(()=>{
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        indoorDeviceInfoApi.remove(record.id).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        indoorDeviceInfoApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if(refreshed){
        this.changePage(this.pageIndex);
      }
    }
  },
  mounted: function() {
    this.changePage(1);
  },
  components: {
    "indoorDeviceInfo-detail": IndoorDeviceInfoDetail
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>